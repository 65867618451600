import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  BuildingFloorEntranceList: [],
  PropertyUnitsCount: 0,
  CurrentOwnerDetailByContractId: {}
};

const buildingSlice = createSlice({
  name: "buildingSlice",
  initialState,
  reducers: {
    BuildingFloorEntranceAction: (state, action) => {
      state.BuildingFloorEntranceList = action.payload.BuildingFloorEntranceList;
    },
    PropertyUnitsCountAction: (state, action) => {
      state.PropertyUnitsCount = action.payload.PropertyUnitsCount;
    },
    CurrentOwnerDetailByContractIdAction: (state, action) => {
      state.CurrentOwnerDetailByContractId = action.payload.CurrentOwnerDetailByContractId;
    },
    ContractOwnerAndRenterDetailsAction: (state, action) => {
      state.ContractOwnerAndRenterDetails = action.payload.ContractOwnerAndRenterDetails;
    },
    PropertyUnitsDetailAction: (state, action) => {
      state.PropertyUnitsDetail = action.payload.PropertyUnitsDetail;
    },
  },
});

export const {
  BuildingFloorEntranceAction,
  PropertyUnitsCountAction,
  CurrentOwnerDetailByContractIdAction,
  ContractOwnerAndRenterDetailsAction,
  PropertyUnitsDetailAction
} = buildingSlice.actions;

export default buildingSlice.reducer;
